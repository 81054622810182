import React, { useState, useEffect } from 'react';
import '../App.css';
import Heading from './Heading';
import ParticleBg from './ParticlesBg';
import H2 from './H2';
import TwoColumns from './TwoColumns';
import WrapTextImgLeft from './WrapTextImgLeft';
import WrapTextImgRight from './WrapTextImgRight';
import GASBackground from '../asset/GASBackground02.png';
import GAS from '../asset/GAS03.png';
import GDOC from '../asset/GoogleDoc.png';
import GSHEET from '../asset/GoogleSheet.png';
import GSLIDE from '../asset/GoogleSlide.png';
import AnimatedVisibility from './AnimatedVisibility';

function preloadImages(images) {
    return Promise.all(images.map(image => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = resolve;
        img.onerror = reject;
      });
    }));
  }

function Page01() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const images = [GASBackground, GAS, GDOC, GSHEET, GSLIDE];
        preloadImages(images).then(() => setIsLoading(false));
    }, []);

    const defaultVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0, transition: {duration: 1} },
    };
    const defaultVariants02 = {
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0, transition: {duration: 1} },
    };
    
    const imageInfo01 = {
        url: GAS,
        caption: "If you've ever wished to extend the capabilities of Google Workspace (formerly G Suite) beyond what's available out of the box, you're in the right place. Google App Script is a powerful, flexible scripting language built on JavaScript that allows you to automate tasks, integrate with other services, and build complex workflows within the Google ecosystem. Whether you're looking to automate repetitive tasks, create custom functions in Google Sheets, or build add-ons for Google Docs, Sheets, and Forms, Google App Script opens up a world of possibilities."
    };
    const imageInfo02 = {
        url: GDOC,
        caption: "This website is designed for anyone eager to unlock the potential of Google Workspace through automation and customization. Whether you're a complete beginner to programming or someone with a bit of coding experience looking to explore what Google App Script can offer, our resources are tailored to meet you at your level."
    };
    const imageInfo03 = {
        url: GSHEET,
        caption: "Google App Script stands out because it is accessible yet powerful. With just a basic understanding of JavaScript, you can start automating Google Workspace applications, accessing Google's APIs, and even creating web applications. It's an excellent way for educators, business professionals, and anyone looking to improve their digital workflows to make an impact with minimal setup and learning curve."
    };
    const imageInfo04 = {
        url: GSLIDE,
        caption: "Ready to embark on your scripting journey? Our step-by-step guide will take you through setting up your first script, understanding the scripting environment, and creating your first automation. Let's unlock the full potential of Google Workspace together, one script at a time."
    };

    if (isLoading) {
        return (
            <div className="App">
            <div className="body">
                <ParticleBg />
                </div>
            </div>
        ) // Optionally, add a loading indicator here
      }

    return (
        <div className="App">
            <div className="App-header">
                <ParticleBg />
                <br></br>
                <Heading content="Google App Scripts" />
                <div>
                    <img src= {GASBackground}
                        style = {{
                            width: 'auto',
                            height: 'auto',
                            borderRadius: '10px',
                            margin: '5px',
                            border: '1px solid #ccc',
                            opacity: 0.9,

                        }
                        }
                    />
                </div>
            </div>
            <div className='body'>
                <br></br>
                <br></br>
                <H2 content="Welcome to Your First Step into the World of Google App Script!" />
                <br></br>
                <AnimatedVisibility variants={defaultVariants}>
                    <WrapTextImgLeft imageInfo={imageInfo01} />
                </AnimatedVisibility>
                <AnimatedVisibility variants={defaultVariants02}>
                    <WrapTextImgRight imageInfo={imageInfo02} />
                </AnimatedVisibility>
                <AnimatedVisibility variants={defaultVariants}>
                    <WrapTextImgLeft imageInfo={imageInfo03} />
                </AnimatedVisibility>
                <AnimatedVisibility variants={defaultVariants02}>
                    <WrapTextImgRight imageInfo={imageInfo04} />
                </AnimatedVisibility>
            </div>
        </div>
    )
}

export default Page01;