import '../App.css';
import Heading from './Heading';
import RunMotion from './RunMotion';
import ParticleBg from './ParticlesBg';
import MyCarousel from './MyCarousel';
import HKWeather from './HKWeather';

function Page04() {
    return (
        <div className="App">
            <div className="App-header">
                <ParticleBg />
                <Heading content="Dashboard (Data Fetching)" />
            </div>
            <div className='body'>
                <HKWeather />
            </div>
        </div>
    )
}

export default Page04;