import React, { useState, useEffect } from 'react';
import H2 from './H2'
import Accordion from 'react-bootstrap/Accordion'
import './HKWeather.css'


function HKWeather() {
  const [weatherData, setWeatherData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch weather data
  const fetchWeatherData = () => {
    const url = 'https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=fnd&lang=en';
    
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setWeatherData(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Failed to fetch data: ", error);
        setError(error.toString());
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchWeatherData(); // Fetch data on component mount
    const interval = setInterval(fetchWeatherData, 3600000); // Fetch data every 1 hour

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  if (isLoading) {
    return <div>Loading weather information...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }

  const formatDate = (dateString) => {
    // Assuming dateString is in the format "YYYYMMDD"
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
  
    // Construct a new date string in ISO format "YYYY-MM-DD"
    const isoDateString = `${year}-${month}-${day}`;
  
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(isoDateString).toLocaleDateString('en-US', options);
  };
  
  
  return (
    <div>
      <br></br>
      <H2 content="Hong Kong Observatory" />
      <br></br>
        {weatherData && weatherData.weatherForecast.map((forecast, index) => (
            <div key={index}>
                <center>
                <Accordion className='custom-accordion'>
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{formatDate(forecast.forecastDate)} ({forecast.week})</Accordion.Header>
                    <Accordion.Body>
                    <p>{forecast.forecastMintemp.value}&deg;C - {forecast.forecastMaxtemp.value}&deg;C</p>
                    <p> {forecast.forecastWeather}</p>
                    </Accordion.Body>
                  </Accordion.Item>
              </Accordion>  
              </center>
            </div>
        ))}
    </div>
  );
}

export default HKWeather;
