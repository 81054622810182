import '../App.css';
import Heading from './Heading';
import RunMotion from './RunMotion';
import ParticleBg from './ParticlesBg';
import MyCarousel from './MyCarousel';

function Email() {
    return (
        <div className="App">
            <div className="App-header">
                <ParticleBg />
                <Heading content="EMAIL" />
            </div>
            <div className='body'>
                <RunMotion line01="Send To:" line02="CPHO@ME.COM" />
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    )
}

export default Email;