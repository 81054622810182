import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {BrowserRouter, Switch, Routes, Route, Link} from "react-router-dom";
import Home from './components/Home';
import Email from './components/Email'
import Page01 from './components/Page01'
import Page02 from './components/Page02'
import Page03 from './components/Page03'
import Page04 from './components/Page04'
import ObjectDetect from './components/ObjectDetect';
import HandPose from './components/HandPose';

function App() {
  const [expanded, setExpanded] = useState(false); // State to manage the navbar collapse
  return (
    <div className="App">
      <div className='Navbar'>
      <BrowserRouter>
      <Navbar bg="dark" data-bs-theme="dark" expand="lg" className="bg-body-tertiary" expanded={expanded}>
        <Container>
          <Navbar.Brand as={Link} to={"/"} onClick={() => setExpanded(false)}>
                <img
                  src="/favicon.ico" // Adjust the path as necessary
                  width="30" // Adjust the size as necessary
                  height="30" // Adjust the size as necessary
                  className="d-inline-block align-top" // Bootstrap classes for alignment
                  alt="Logo"
                />{' '}
            CPHO.ME</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)}/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to={"/home"} onClick={() => setExpanded(false)}>Home</Nav.Link>
              <NavDropdown title="Coding" id="basic-nav-dropdown" align="end">
                <NavDropdown.Item as={Link} to={"/Page01"} onClick={() => setExpanded(false)}>Google App Script (Updating)</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to={"/Page02"} onClick={() => setExpanded(false)}>Machine Learning (coming soon)</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/Page03"} onClick={() => setExpanded(false)}>React Native (coming soon)</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Experiments" id="basic-nav-dropdown" align="end">
                <NavDropdown.Item as={Link} to={"/Page04"} onClick={() => setExpanded(false)}>Dashboard (Updating)</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/ObjectDetect"} onClick={() => setExpanded(false)}>TensorFlow.JS (ObjectDetect)</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/HandPose"} onClick={() => setExpanded(false)}>TensorFlow.JS (HandPose)</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to={"/"} onClick={() => setExpanded(false)}>Map (coming soon)</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to={"/Email"} onClick={() => setExpanded(false)}>Email</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <Routes>
          <Route path="/" element={<Home />}/>
        </Routes>
        <Routes>
          <Route path="/home" element={<Home />}/>
        </Routes>
        <Routes>
          <Route path="/Page01" element={<Page01 />}/>
        </Routes>
        <Routes>
          <Route path="/Page02" element={<Page02 />}/>
        </Routes>
        <Routes>
          <Route path="/Page03" element={<Page03 />}/>
        </Routes>
        <Routes>
          <Route path="/Page04" element={<Page04 />}/>
        </Routes>
        <Routes>
          <Route path="/ObjectDetect" element={<ObjectDetect />}/>
        </Routes>
        <Routes>
          <Route path="/HandPose" element={<HandPose />}/>
        </Routes>
        <Routes>
          <Route path="/Email" element={<Email />}/>
        </Routes>
        

      </div>
      </BrowserRouter>
      </div>
    </div>
  );
}

export default App;