import '../App.css';
import Heading from './Heading';
import RunMotion from './RunMotion';
import ParticleBg from './ParticlesBg';
import MyCarousel from './MyCarousel';

function Page03() {
    return (
        <div className="App">
            <div className="App-header">
                <ParticleBg />
                <Heading content="React Native" />
            </div>
            <div className='body'>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    )
}

export default Page03;