export const drawRect = (detections, ctx) => {
    detections.forEach(prediction=>{
        const [x,y,width,height] = prediction['bbox'];
        const text = prediction['class'];

        const color = 'green';
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;
        ctx.font = '18px Arial'
        ctx.fillStyle = 'green';
        
        ctx.beginPath();
        ctx.fillText(text, x, y);
        ctx.rect(x, y, width, height);
        ctx.stroke();
    })
}