import React from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from 'react';

function ParticleBg() {
    const loadParticles = useCallback(async engine => {
        console.log(engine);
        await loadFull(engine);
    }, []);

    const particlesloaded = useCallback(async container => {
        await console.log(container);
    }, []);
    return (
        <div>
        <Particles
         id="tsparticles"
         init={loadParticles}
         loaded={particlesloaded}
         options={{
            background: {
                color: {
                    value: "black",
                },
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                    resize: true,
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#ffffff",
                },
                links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: false,
                    speed: 1,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        area: 800,
                    },
                    value: 80,
                },
                opacity: {
                    value: 0.8,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 0.5, max: 2 },
                },
            },
            detectRetina: true,
            }}
        />
         </div>
    )      
}

export default ParticleBg