// 1. Install dependencies DONE
// 2. Import dependencies DONE
// 3. Setup webcam and canvas DONE
// 4. Define references to those DONE
// 5. Load handpose DONE
// 6. Detect function DONE
// 7. Drawing utilities DONE
// 8. Draw functions DONE

import React, { useRef, useState, useEffect } from "react";
// import logo from './logo.svg';
import * as tf from "@tensorflow/tfjs";
import * as handpose from "@tensorflow-models/handpose";
import Webcam from "react-webcam";
import "../App.css";
import { drawHand } from "./drawHand";
import ParticleBg from "./ParticlesBg";
import Heading from "./Heading";
import H5 from "./H5";
import Button from 'react-bootstrap/Button';

function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform));
  }

function HandPose() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [facingMode, setFacingMode] = useState("user");
  const isMobile = Boolean(isMobileDevice());

  const runHandpose = async () => {
    const net = await handpose.load();
    console.log("Handpose model loaded.");
    //  Loop and detect hands
    setInterval(() => {
      detect(net);
    }, 100);
  };

  const detect = async (net) => {
    // Check data is available
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas height and width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // Make Detections
      const hand = await net.estimateHands(video);
      console.log(hand);

      // Draw mesh
      const ctx = canvasRef.current.getContext("2d");
      drawHand(hand, ctx);
    }
  };

  const toggleCamera = async () => {
    setFacingMode(facingMode === "user" ? "environment" : "user");
  };

  runHandpose();

  return (
    <div className="App">
      <div className="App-header">
        <ParticleBg />
        <br></br>
        <Heading content="Real Time Hand Pose Drawing" />
        <H5 content="Instantly identifies your hand through webcam's view using TensorFlow.js and handpose model." />
        <H5 content="Wait around 1 minute for the model to work." />
        <H5 content="No downloads or installations required—experience the power of AI directly in your web browser." />
        <H5 content="Please allow camera access and wait for a while to load" />
        <br></br>
        {isMobile && (
                <Button onClick={toggleCamera} variant="dark">Toggle Front/Back Camera</Button>
        )}
      </div>
      <div className="body">
      <Webcam
          ref={webcamRef}
          muted={true} 
          videoConstraints={{ facingMode }}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: '80vw',
            height: '80vh',
          }}
        />

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 8,
            width: '80vw',
            height: '80vh',
          }}
        />
      </div>
    </div>
  );
}
export default HandPose;