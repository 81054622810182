import React from 'react';

function WrapTextImgLeft({imageInfo}) {
    const style = {
        padding: '20px',
        margin: '10px'
    };
    return (
        <div style={style}>
            <img
                style={{maxHeight:"10vh"}}
                className="float-start imgshadow me-3"
                src={ imageInfo.url }
                alt="First slide"
            />
        <p className="fs-5" align="left"><font color="white">{ imageInfo.caption }</font></p>
        </div>
    )
}

export default WrapTextImgLeft;